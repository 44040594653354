import React from 'react'
import SEO from 'components/SEO/SEO'
import {
  ContentSection,
  ContentTitle,
  FieldSection,
  FieldTitle,
  FieldParagraph,
  LinkToFile,
} from 'components/StyledComponents/StyledComponents'
import { useStaticQuery, graphql } from 'gatsby'

const GuideForAuthorsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "guideForAuthors"}}) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)
return (
  <>
    <SEO title="Guide for Authors" />
    <ContentSection>
      <ContentTitle>Guide for Authors</ContentTitle>
      <FieldSection>
        <FieldParagraph>
          Rural Areas and Development publishes original scientific papers,
          communications, up-to-date review articles reviews from the
          disciplines concerned. Papers are published in English. The Board of
          Editors decides on the publication of papers, taking into account peer
          reviews, scientific importance, and manuscript quality. The journal
          uses the double-blind peer review, which means that both authors and
          reviewers are anonymous to each other throughout the review process.
        </FieldParagraph>
        <FieldParagraph>
          One author has been designated as the corresponding author with
          contact details: e-mail address and full postal address.
        </FieldParagraph>
        <FieldParagraph>
          All necessary files have been uploaded:
          <br />
          Manuscript should include abstract of no more than 300 words,
          keywords, all figures should include relevant captions, all tables
          should include titles, description, footnotes. Please ensure all
          figure and table citations in the text match the files provided.
          Indicate clearly if colour should be used for any figures in print.
          Send supplemental files where applicable.
        </FieldParagraph>
        <FieldTitle inside>Language</FieldTitle>
        <FieldParagraph>
          Please write your text in good English. Authors should make sure to
          eliminate possible grammatical or spelling errors and to conform to
          correct scientific English of their manuscripts.
        </FieldParagraph>
        <FieldTitle inside>Copyright</FieldTitle>
        <FieldParagraph>
          The journal is protected by copyright held by the publisher after the
          manuscript has been accepted for publication. In respect to the
          transfer of rights, the corresponding author takes over responsibility
          for all authors.
        </FieldParagraph>
        <br />
        <FieldParagraph>
          Guidelines for Authors and declaration can be downloaded from here:
        </FieldParagraph>
        {data.allFile.edges.map(({node}) => {
          const {name, publicURL} = node
          return (
            <FieldParagraph listItem><LinkToFile href={publicURL}>{name}</LinkToFile></FieldParagraph>
          )
        })}
      </FieldSection>
    </ContentSection>
  </>
)}

export default GuideForAuthorsPage
